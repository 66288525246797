import type { JSX } from 'react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { MarketplaceFrontendRoutes } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../store/typings/store.js';
import './i18n.js';
import { CarDetailsLayout } from './layout/car-details/CarDetailsLayout.js';
import { MarketplaceAppLayout } from './layout/default/MarketplaceAppLayout.js';
import './scss/styles';

export interface MarketplaceAppProps {
  readonly $gs: GlobalStoreStateSelector<MPStoreDef>;
}

export function MarketplaceApp({ $gs }: MarketplaceAppProps): JSX.Element {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<CarDetailsLayout $gs={$gs} />}>
          <Route
            path={MarketplaceFrontendRoutes.CAR_DETAILS(':companyId', ':siteId', ':carId')}
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/car-details/CarDetailsPage.js')).CarDetailsPage,
            })}
          />
        </Route>
        <Route element={<MarketplaceAppLayout $gs={$gs} />}>
          <Route
            path={MarketplaceFrontendRoutes.CARS}
            loader={() => ({ $: $gs.$cars, $window: $gs.$window, $bulmaTheme: $gs.$bulmaTheme })}
            lazy={async () => ({
              Component: (await import('./pages/cars/CarsPage.js')).CarsPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.BUY}
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/buy/BuyPage.js')).BuyPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.SELL}
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/sell/SellPage.js')).SellPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.REFRESH}
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/refresh/RefreshPage.js')).RefreshPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.COACHING}
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/coaching/CoachingPage.js')).CoachingPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.SITES}
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/sites/SitesPage.js')).SitesPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.LEGAL_MENTIONS}
            lazy={async () => ({
              Component: (await import('./pages/legal/LegalMentionsPage.js')).LegalMentionsPage,
            })}
          />
          <Route
            path={MarketplaceFrontendRoutes.GENERAL_CONDITIONS}
            lazy={async () => ({
              Component: (await import('./pages/general-conditions/GeneralConditionsPage.js'))
                .GeneralConditionsPage,
            })}
          />
          <Route
            path="*"
            loader={() => $gs}
            lazy={async () => ({
              Component: (await import('./pages/home/HomePage.js')).HomePage,
            })}
          />
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
}
