import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import { MarketplaceFrontendRoutes } from '@stimcar/marketplace-libs-common';
import type { MarketplaceAppProps } from '../../App.js';
import { Image } from '../../components/common/image/Image.js';
import { ContactButton } from '../../components/contact/ContactButton.js';
import './Footer.scss';

const STIMCAR_CORPORATE_WEBSITE_URL = 'https://corporate.stimcar.fr/';
const STIMCAR_CORPORATE_WEBSITE_JOIN_US_URL = 'https://corporate.stimcar.fr/nous-rejoindre/';
const STIMCAR_INSTAGRAM_URL = 'https://www.instagram.com/stim_car/';
const STIMCAR_LINKEDIN_URL = 'https://fr.linkedin.com/company/stimcar';
const STIMCAR_FACEBOOK_URL = 'https://www.facebook.com/stimcar';

interface SocialMediaIconProps {
  readonly to: string;
  readonly socialMedia: 'instagram' | 'linkedin' | 'facebook';
}

function SocialMediaIcon({ to, socialMedia }: SocialMediaIconProps): JSX.Element {
  return (
    <NavLink to={to} target="_blank" rel="noopener noreferrer" aria-label={socialMedia}>
      <span className="icon is-large social-media-icon has-text-black has-background-white mx-1">
        <i className={`fa-brands fa-${socialMedia} fa-lg`} />
      </span>
    </NavLink>
  );
}

interface FooterLinkProps {
  readonly to: string;
  readonly label: string;
  readonly isExternal?: boolean;
}

function FooterLink({ to, label, isExternal = false }: FooterLinkProps): JSX.Element {
  const additionalProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <NavLink to={to} {...additionalProps} className="cell link has-text-white has-text-weight-bold">
      {label}
    </NavLink>
  );
}

type StimcarExperienceSectionProps = MarketplaceAppProps;

function StimcarExperienceSection({ $gs }: StimcarExperienceSectionProps) {
  const [t] = useTranslation('marketplace');
  return (
    <div
      className={` 
        px-3
        mt-3 
        has-text-centered
        is-align-content-center
        stimcar-experience-container 
        has-background-primary-gradient`}
    >
      <h3 className="title has-text-white has-text-centered">{t('home.stimcarExperience')}</h3>
      <ContactButton
        $gs={$gs}
        reason="request"
        label={t('home.contactUs')}
        className="stimcar-white-primary-button px-auto"
      />
    </div>
  );
}

type KnowMoreSectionProps = MarketplaceAppProps;

function KnowMoreSection({ $gs }: KnowMoreSectionProps) {
  const [t] = useTranslation('marketplace');
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  return (
    <div className="mt-5">
      <h4 className="title is-4 has-text-centered pt-3">{t('home.knowMore.title')}</h4>
      <div className="columns px-5 pb-5">
        <div className={`column ${isMobile ? '' : 'mr-2'}`}>
          <Image
            height="200px"
            width={isMobile ? '303px' : '532px'}
            alt={t('home.knowMore.beAccompaniedAlt')}
            src="img/footer/man-showing-car-engine.webp"
            className={`be-accompanied-picture ${isMobile ? 'mobile' : ''} has-fit-cover is-fullwidth-image`}
          />
          <h4 className="title is-4 mt-2 mb-1">{t('home.knowMore.beAccompanied')}</h4>
          <p className={`subtitle know-more-subtitle is-6 ${isMobile ? 'mb-3' : 'mb-0'}`}>
            {t('home.knowMore.beAccompaniedDescription')}
          </p>
          <ContactButton
            $gs={$gs}
            reason="request"
            label={t('home.knowMore.findACoach')}
            className="px-5 stimcar-gradient-button"
          />
        </div>
        <div className={`column ${isMobile ? '' : 'ml-2'}`}>
          <Image
            height="200px"
            width={isMobile ? '303px' : '532px'}
            src="img/footer/stimcar-club.webp"
            alt={t('home.knowMore.joinStimcarClubAlt')}
            className={`stimcar-club-picture ${isMobile ? 'mobile mt-5' : ''} has-fit-cover is-fullwidth-image`}
          />
          <h4 className="title is-4 mt-2 mb-1">{t('home.knowMore.joinStimcarClub')}</h4>
          <p className={`subtitle know-more-subtitle is-6 ${isMobile ? 'mb-3' : 'mb-0'}`}>
            {t('home.knowMore.joinStimcarClubDescription')}
          </p>
          <NavLink
            to={MarketplaceFrontendRoutes.COACHING}
            className="stimcar-gradient-button navigation-button px-6"
          >
            {t('home.knowMore.discoverTheClub')}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

type LinksSectionProps = MarketplaceAppProps;

function LinksSection({ $gs }: LinksSectionProps) {
  const [t] = useTranslation('marketplace');
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  return (
    <div className="links-section has-dark-background mt-3">
      <div>
        <Image
          width="225px"
          height="60px"
          className="logo"
          src="stimcarlogo-dark.svg"
          alt={t('common.stimcarLogoAlt')}
        />
      </div>
      <div className="separator has-background-primary-gradient mt-5" />
      <div
        className={`columns${isMobile ? ' is-flex is-align-items-center is-flex-direction-column' : ''}`}
      >
        <div className={`column has-text-white mt-5 ${isMobile ? 'has-text-centered' : ''}`}>
          <p>{t('home.footer.contact.address')}</p>
          <p>{t('home.footer.contact.city')}</p>
          <NavLink
            to={`mailto:${t('home.footer.contact.email')}`}
            className="has-text-white has-text-weight-bold is-underlined"
          >
            {t('home.footer.contact.email')}
          </NavLink>
        </div>
        <div
          className={`column is-half fixed-grid ${isMobile ? 'has-1-cols grid-mobile' : 'ml-5'} mt-5`}
        >
          <div className="grid has-text-white has-text-weight-bold">
            <FooterLink label={t('home.footer.links.buy')} to={MarketplaceFrontendRoutes.BUY} />
            <FooterLink label={t('home.footer.links.sites')} to={MarketplaceFrontendRoutes.SITES} />
            <FooterLink label={t('home.footer.links.sell')} to={MarketplaceFrontendRoutes.SELL} />
            <ContactButton
              $gs={$gs}
              reason="request"
              className={`contact-button has-no-border p-0 has-text-white${isMobile ? '' : ' has-text-left'}`}
              label={t('home.footer.links.contact')}
            />
            <FooterLink
              label={t('home.footer.links.refresh')}
              to={MarketplaceFrontendRoutes.REFRESH}
            />
            <FooterLink
              isExternal
              label={t('home.footer.links.joinUs')}
              to={STIMCAR_CORPORATE_WEBSITE_JOIN_US_URL}
            />
            <FooterLink
              label={t('home.footer.links.coaching')}
              to={MarketplaceFrontendRoutes.COACHING}
            />
            <FooterLink
              isExternal
              to={STIMCAR_CORPORATE_WEBSITE_URL}
              label={t('home.footer.links.whoAreWe')}
            />
            <FooterLink
              label={t('home.footer.links.legalMentions')}
              to={MarketplaceFrontendRoutes.LEGAL_MENTIONS}
            />
            <FooterLink
              label={t('home.footer.links.generalConditions')}
              to={MarketplaceFrontendRoutes.GENERAL_CONDITIONS}
            />
          </div>
        </div>
        <div className="column mt-5 has-text-right">
          <SocialMediaIcon socialMedia="instagram" to={STIMCAR_INSTAGRAM_URL} />
          <SocialMediaIcon socialMedia="linkedin" to={STIMCAR_LINKEDIN_URL} />
          <SocialMediaIcon socialMedia="facebook" to={STIMCAR_FACEBOOK_URL} />
        </div>
      </div>
    </div>
  );
}

type FooterProps = MarketplaceAppProps;

export function Footer({ $gs }: FooterProps): JSX.Element {
  return (
    <footer className="marketplace-footer">
      <StimcarExperienceSection $gs={$gs} />
      <KnowMoreSection $gs={$gs} />
      <LinksSection $gs={$gs} />
    </footer>
  );
}
